import * as React from 'react';
import AboutInternship from '../components/internship/aboutinternship';
import DarkHeader from '../components/layout/darkheader';
import Layout from '../components/layout/layout';
import Seo from '../components/seo';
import './internship.scss';
import './mainpage.scss';

import { graphql } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import FindDreamJob from '../components/careers/finddreamjob';
import Ceo from '../components/culture/ceo';
import AboutInternshipBrief from '../components/internship/aboutinternshipbrief';
import WorkTeam from '../components/internship/workteam';
import ButtonApplyNow from '../components/layout/buttonapplynow';

const InternshipPage = () => {
  const { i18n } = useI18next();
  const { t } = useTranslation();
  const pageTitle = t('title', { ns: 'internship' });
  const pageDescription = t('The online coding school that invests in you', { ns: 'internship' });
  const meta = [];
  const breadcrumbMap = [];
  const title2 = t('The online coding school that invests in you', { ns: 'internship' });
  const sectionClassName = 'section-internship-header';

  return (
    <>
      <Layout breadcrumbMap={breadcrumbMap} headerTransparent={true} lang={i18n.resolvedLanguage}>
        <div className='max-container-pages'>
          <Seo title={pageTitle} lang={i18n.resolvedLanguage} description={pageDescription} meta={meta} />
          <DarkHeader
            title1={pageTitle}
            title2={title2}
            sectionClassName={sectionClassName}
            callButton={
              <ButtonApplyNow
                ariaLabel={t('aria-label-button')}
                title={[t('Apply to Join'), t('BTS Internship Program')]}
                formVariant='signme'
                arrow={false}
              >
                {t('Apply Now')}
              </ButtonApplyNow>
            }
          >
            <p>{t('From the very start of our company')}</p>
          </DarkHeader>
          <AboutInternship id='goContent'></AboutInternship>
          <WorkTeam />
          <AboutInternshipBrief title={t('Join our Talent Network', { ns: 'common' })}></AboutInternshipBrief>
          <Ceo />
          <FindDreamJob />
        </div>
      </Layout>
    </>
  );
};

export default InternshipPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "internship"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
