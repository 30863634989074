import "./aboutinternship.scss"
import * as React from "react"
import { Col, Row, Button } from 'react-bootstrap'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useStaticQuery, graphql } from 'gatsby';

const AboutInternship = ({ id = "section-generic" }) => {
    const [toggle, setToggle] = React.useState(false);
    const { t, i18n } = useTranslation()
    const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "careers" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `);

    const images = {
        "who-we-are": null,
        "who-we-are-full": null,
        "who-we-are-laptop": null
    }

    Object.keys(images).forEach(key=> {
        const image= images[key]=data.allFile.edges.find(
            edge => edge.node.name === `${key}-${i18n.language.toLowerCase()||"en"}`
        );
        console.log(key,image)
        images[key] = getImage(image.node);
    })


    return (
        <>
            <section id={id} className="section-internship-about">
                <Row className="flex-reverse">
                    <Col className={i18n.language.toLowerCase() === "es" ? "col-img es-bg" : "col-img"} lg={6}>
                        <GatsbyImage
                            className="d-md-none"
                            layout="fullWidth"
                            //height={580}
                            alt="Who we are"
                            image={images["who-we-are"]}
                            formats={["auto", "webp", "avif"]}
                        />
                        <GatsbyImage
                            className="d-none d-md-block d-lg-none"
                            layout="fullWidth"
                            //height={580}
                            alt="Who we are"
                            image={images["who-we-are-full"]}
                            formats={["auto", "webp", "avif"]}
                        />
                        <GatsbyImage
                            className="d-none d-lg-block d-xl-none"
                            fit="fill"
                            //height={580}
                            alt="Who we are"
                            image={images["who-we-are-laptop"]}
                            formats={["auto", "webp", "avif"]}
                        />

                    </Col>
                    <Col className={i18n.language.toLowerCase() === "es" ? "col-text es-font" : "col-text"} lg={6}>
                        <h4 className="header-square green md-blue md-large">{t("About BTS Internship Program")}</h4>
                        <p>
                            {t("During a 6-to-8-month period, interns are trained by our own senior developers")}
                        </p>
                        <p>
                            {t("The interns who successfully complete this program are directly hired by our company")}
                        </p>
                        <div id="more-information" className={toggle ? "d-block" : "d-none"}>
                            <p>
                                {t("We currently have 2 programs available")}
                            </p>
                            <p>
                                {t("Our senior employees volunteer and the support to our young developers do not stop there")}
                            </p>
                        </div>
                        <Button className="link-grey-btn w-half d-block d-md-none" variant="link" onClick={() => { setToggle(!toggle) }}>
                            {t("Read More")}
                        </Button>
                    </Col>
                </Row>
            </section>
        </>
    )
};

export default AboutInternship
